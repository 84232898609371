import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { APP_ACTIONS, selectAuthenticated } from './store/app.store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';

declare var SockJS: any;
declare var Stomp: any;
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private readonly store = inject(Store);
  public stompClient: any;
  private userSubscription: any;
  private connectSubject$ = new Subject<any>();
  private errorSubject$ = new Subject<any>();
  private closeSubject$ = new Subject<void>();
  private userIdMsgSubject$ = new Subject<any>();
  private jwtService: JwtHelperService = new JwtHelperService();
  initializeWebSocketConnection() {
    if (this.isConnected()) return;
    const that = this;
    const token = localStorage.getItem('access_token');
    if (!token || !this.store.selectSignal(selectAuthenticated)) {
      console.error('not authenticated so stopped connecting to websocket....');
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const serverUrl = `${environment.apiUrl}ws`;
    const ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.reconnect_delay = 5000;

    const decodedToken = this.jwtService.decodeToken(token);
    // tslint:disable-next-line:only-arrow-functions
    this.stompClient.debug = (e: any) => {
      console.log('debug ', e);
    }; //disable stomp debug
    this.stompClient.connect(
      headers,
      (frame: any) => {
        that.jwtService.decodeToken();
        that.userSubscription = this.stompClient.subscribe(
          `/topic/${decodedToken.sub}`,
          (res: any) => {
            that.userIdMsgSubject$.next(res);
          }
          // { id: this.decodedToken.sub }
        );
        that.connectSubject$.next(frame);
      },
      (error: any) => {
        if (error['body']) {
          console.error('error websocket ', error['body']);
        }
        this.errorSubject$.next(error);
      }
    ); //for other kind of messages like room full,not found
    this.stompClient.onclose = () => {
      that.closeSubject$.next();
    };
  }

  public onConnect() {
    return this.connectSubject$.asObservable();
  }
  public onConnectError() {
    return this.errorSubject$.asObservable();
  }
  public onConnectionClose() {
    return this.closeSubject$.asObservable();
  }
  public onUsersTopicMsg() {
    return this.userIdMsgSubject$.asObservable();
  }
  private setLoading(loading: boolean) {
    this.store.dispatch(
      APP_ACTIONS.setLoading({
        loading: loading,
      })
    );
  }
  public unsubscribeToTopic(topic: string | null) {
    if (!topic) return;
    this.stompClient.unsubscribe(`/topic/${topic}`);
  }

  public disconnect() {
    this.stompClient?.disconnect();
  }

  public subscribeToTopic(topic: string, callback?: (res: any) => void) {
    return this.stompClient.subscribe(`/topic/${topic}`, (res: any) => {
      if (callback) callback(res);
    });
  }

  public isConnected() {
    return this.stompClient?.connected;
  }

  public sendMessage(topic: string, object: unknown) {
    this.stompClient.send(`/app/${topic}`, {}, object);
  }
}
