import { format } from 'date-fns';
import { JwtHelperService } from '@auth0/angular-jwt';
import html2canvas from 'html2canvas';
import { BehaviorSubject, map, takeWhile, tap, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
export const helper = new JwtHelperService();

export function convertKatexCode(input: string, className: string) {
  if (!input) return '';
  const katexRegex = /<k>(.*?)<\/k>/g;

  // Replace KaTeX code with actual code
  const output = input.replace(
    katexRegex,
    (match, group) => `<span class="katex-${className}">${group}</span>`
  );
  return output;
}
export function getRandomInt(max: number) {
  if (max < 2) max = 2;
  const min = 0;

  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export function getTimeDiff(time: number) {
  const now = new Date();
  const isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  return isoDate.getTime() - time;
}
export function generateRandomArray(max: number) {
  const arr = new Array(max);

  for (let i = 0; i < max; i++) {
    arr[i] = i;
  }

  return shuffleArray(arr);
}

export function formatToJavaDT(dateTime: Date): string {
  return format(dateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
}
export function shuffleArray<T>(array: Array<T>) {
  //strict mode doesnt allow shuffling array in global mode
  const newArr = [...array];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
}

export function isTokenExpired(token: string | null): boolean {
  if (!token) return true;
  return helper.isTokenExpired(token);
}

export function divideArrayIntoParts<T>(array: T[], size: number) {
  // Calculate the size of each part
  // let partSize = Math.ceil(array.length / parts);

  // Initialize an array to hold the 4 parts
  let result = [];

  let total = 0;

  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    total += chunk.length;
    result.push(chunk);
  }

  //push remaining
  if (total < array.length) {
    result.push(array.slice(total, array.length - total));
  }
  return result;
}

export function generateNonce(length: number = 32): string {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Use crypto API if available
  if (window.crypto && window.crypto.getRandomValues) {
    let values = new Uint32Array(length);
    window.crypto.getRandomValues(values);
    for (let i = 0; i < length; i++) {
      text += possible.charAt(values[i] % possible.length);
    }
  } else {
    // Fallback for older browsers
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
  }

  return text;
}
// export const facultiesMap = {
//   ENGINEERING: [
//     'CIVIL',
//     'SOFTWARE',
//     'COMPUTER',
//     'MECHANICAL',
//     'ELECTRICAL',
//     'ELECTRONICS_AND_COMMUNICATION',
//     'ELECTRONICS_COMMUNICATION_AND_INFORMATION',
//   ],
//   NURSING: ['PCL', 'BSC'],
//   DRIVING: ['BIKE', 'CAR'],
//   PHARMACY: ['B_PHARM', 'D_PHARM'],
//   PHYSIOTHERAPY: ['B_PHYSIO','D_PHYSIO']
// };

// export const faculties = Object.keys(facultiesMap);

// export type FACULTIES = keyof typeof facultiesMap;

export const convertToBlob = (file: File, cb: (blob: Blob) => void) => {
  const reader = new FileReader();
  reader.onload = (event: ProgressEvent<FileReader>) => {
    if (event.target == null) return;
    let blob: Blob;
    if (typeof event.target.result === 'string') {
      // Handle the case when event.target.result is a string
      blob = new Blob([event.target.result], { type: file.type });
    } else if (event.target.result instanceof ArrayBuffer) {
      // Handle the case when event.target.result is an ArrayBuffer
      blob = new Blob([event.target.result], { type: file.type });
    } else {
      // Handle the case when event.target.result is null or an unexpected type
      console.error(
        'Unexpected data type from FileReader:',
        typeof event.target.result
      );
      return;
    }
    cb(blob);
  };
  reader.readAsArrayBuffer(file);
};

export const convertBase64ToBlob = (Base64Image: string) => {
  // split into two parts
  const parts = Base64Image.split(';base64,');
  // hold the content type
  const imageType = parts[0].split(':')[1];
  // decode base64 string
  const decodedData = window.atob(parts[1]);
  // create unit8array of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);
  // insert all character code into uint8array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  // return blob image after conversion
  return new Blob([uInt8Array], { type: imageType });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsDataURL(blob);
  });
};

export function createTimerObservable(
  start: Date,
  expiryInMin: number,
  expired$: BehaviorSubject<string>
) {
  return timer(0, 1000).pipe(
    takeWhile(() => expired$.value !== 'UP'),
    map(() => {
      const now = new Date();
      const elapsedMilliseconds = now.getTime() - start.getTime();
      const seconds = Math.floor(elapsedMilliseconds / 1000);
      const minutes = Math.floor(seconds / 60);

      const hour = Math.floor(minutes / 60);

      if (minutes >= expiryInMin) {
        return 'UP';
      }
      const remainingSeconds = seconds % 60;
      const h = `${hour}:${minutes % 60}:${
        remainingSeconds < 10 ? '0' : ''
      }${remainingSeconds}`;

      return h;
    }),
    tap((v) => {
      if (v === 'UP') {
        expired$.next('UP');
        expired$.complete();
      }
    })
  );
}

export function datetoUtc(value: string, formatStr?: string): string {
  const utcDate = new Date(value);

  const localTimeZoneOffset = new Date().getTimezoneOffset();

  const localDate = new Date(
    utcDate.getTime() - localTimeZoneOffset * 60 * 1000
  );

  return format(localDate, formatStr ?? 'yyyy-MM-dd HH:mm:ss');
}

export async function html2Blob(
  node: any,
  onBlob: (blob: Blob | null) => Promise<void>
) {
  if (!node) {
    onBlob(null);
    return;
  }
  // Capture the div as a canvas
  const canvas = await html2canvas(node, {
    backgroundColor: '#232c41', // Transparent background
  });
  canvas.toBlob(onBlob);
}

export async function updateQueryParam(
  router: Router,
  route: ActivatedRoute,
  appendQps: { paramKey: string; paramValue: string }[]
) {
  let queryParams = {
    ...route.snapshot.queryParams,
  };
  appendQps.forEach((qp) => {
    queryParams = {
      ...queryParams,
      [qp.paramKey]: qp.paramValue,
    };
  });

  await router.navigate([], {
    relativeTo: route,
    queryParams,
    replaceUrl: true,
  });
}


