import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Toast } from 'ngx-toastr';

@Component({
    selector: '[custom-toast-component]',
    styleUrls: [`./custom-toast.component.scss`],
    templateUrl: `./custom-toast.component.html`,
    imports: [NgIf],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false
})
export class CustomToastComponent extends Toast implements OnInit {
  toastImageSrc = '/assets/images/toast/error.png';
  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  ngOnInit(): void {
    // Determine the toastr class and set the image source accordingly
    if (this.options.toastClass.includes('toast-error')) {
      this.toastImageSrc = '/assets/images/toast/error.png';
    } else if (this.options.toastClass.includes('toast-warn')) {
      this.toastImageSrc = '/assets/images/toast/warn.png';
    } else if (this.options.toastClass.includes('toast-success')) {
      this.toastImageSrc = '/assets/images/toast/success.png';
    } else {
      this.toastImageSrc = '/assets/images/toast/info.png';
    }
  }
}
