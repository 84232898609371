import { inject, Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Observable, Subject } from 'rxjs';
import { Count, SystemNotification } from './notification/notification.store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './websocket.service';
import { parseDocumentSize } from 'html2canvas/dist/types/css/layout/bounds';

@Injectable({
  providedIn: 'root',
})
export class SystemnotificationService extends AbstractService<SystemNotification> {
  override serviceName(): string {
    return 'system-notifications';
  }

  private readonly wss = inject(WebsocketService);
  private readonly NOTIFICATION_PREFIX = 'notification_';
  private readonly NOTIFICATION_TOPIC = 'notification';
  private readonly generalMsgSubject$ = new Subject<SystemNotification>();
  private readonly categoryMsgSubject$ = new Subject<SystemNotification>();

  public onMsgInGeneral() {
    return this.generalMsgSubject$.asObservable();
  }
  public onMsgInCategory() {
    return this.categoryMsgSubject$.asObservable();
  }

  public parseMsg(msg: any) {
    if (!msg || !msg.body) return null;

    return JSON.parse(msg.body) as SystemNotification;
  }
  public subscribeWs(categoryId: number) {
    this.subscribeToGeneralTopic((msg) => {
      const parsed = this.parseMsg(msg);
      if (parsed) {
        this.generalMsgSubject$.next(parsed);
      }
    });
    this.subscribeToCategory(categoryId, (msg) => {
      const parsed = this.parseMsg(msg);
      if (parsed) {
        this.categoryMsgSubject$.next(parsed);
      }
    });
  }
  public subscribeToGeneralTopic(onMessage: (msg: any) => void) {
    this.wss.subscribeToTopic(this.NOTIFICATION_TOPIC, onMessage);
  }
  public subscribeToCategory(
    categoryId: number,
    onMessage: (msg: any) => void
  ) {
    this.wss.subscribeToTopic(this.NOTIFICATION_PREFIX + categoryId, onMessage);
  }

  public unsubscribe(categoryId: number) {
    this.wss.unsubscribeToTopic(this.NOTIFICATION_PREFIX + categoryId);
  }

  public unsubscribeToGeneralTopic() {
    this.wss.unsubscribeToTopic(this.NOTIFICATION_TOPIC);
  }
}
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly apiUrl = environment.apiUrl;

  private readonly http = inject(HttpClient);

  getCount(): Observable<Count> {
    return this.http.get<Count>(`${this.apiUrl}seen/not`);
  }

  markAllNotification(name: string) {
    this.http.post(`${this.apiUrl}seen/mark-all/${name}`, {}).subscribe({
      next: () => {
        // Handle the response if needed
      },
      error: (err) => {
        // Handle error if needed
        console.error('Error marking notifications:', err);
      },
    });
  }
  markIndividualNotification(id: number) {
    const body = {
      entityId: id,
    };
    this.http.post(`${this.apiUrl}seen`, body).subscribe({
      next: (response) => {
        // Handle the response if needed
        console.log('notification marked as seen:', response);
      },
      error: (err) => {
        // Handle error if needed
        console.error('Error marking notifications:', err);
      },
    });
  }
}
