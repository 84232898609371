import { Injectable, NgZone, inject } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { SignupInterface, User } from './signup/store/signup.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { APP_ACTIONS, selectAuthenticated } from './store/app.store';
import { helper, isTokenExpired } from './utils/Utils';
import { Transaction } from './profile/store/profile.store';
import { use } from 'marked';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly ngZone = inject(NgZone);
  private readonly store = inject(Store);
  private jsonUrl = 'assets/coinHistory.json';
  private readonly apiUrl = environment.apiUrl + 'auth/';

  constructor() {}

  public get accessToken(): any {
    return localStorage.getItem('access_token');
  }

  public set accessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  public get refreshToken(): any {
    return localStorage.getItem('refresh_token');
  }

  public set refreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  public get sessionState(): any {
    return localStorage.getItem('session_state');
  }

  public set sessionState(token: string) {
    localStorage.setItem('session_state', token);
  }

  loginWithIDP(access_token: string, subject_issuer: 'google' | 'apple') {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        session_state: string;
      }>(
        `${this.apiUrl}login-with-idp`,
        {
          access_token,
          subject_issuer,
        },
        {
          headers: headers,
        }
      )
      .pipe(
        tap(({ access_token, refresh_token, session_state }) => {
          this.setTokens(access_token, refresh_token, session_state);
        })
      );
  }

  login(email: string, password: string): Observable<{ access_token: string }> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        session_state: string;
      }>(
        `${this.apiUrl}login`,
        {
          email,
          password,
        },
        {
          headers: headers,
        }
      )
      .pipe(
        tap(({ access_token, refresh_token, session_state }) => {
          this.setTokens(access_token, refresh_token, session_state);
        })
      );
  }

  i: any;
  startRefreshTokenInterval() {
    const that = this;
    this.ngZone.runOutsideAngular(() => {
      clearInterval(that.i);
      that.i = setInterval(() => {
        if (
          !that.accessToken ||
          !that.refreshToken ||
          !that.sessionState ||
          isTokenExpired(that.refreshToken)
        ) {
          const prevAuth = that.store.selectSignal(selectAuthenticated)();
          //only set auth false if previously not false;
          if (prevAuth)
            that.store.dispatch(
              APP_ACTIONS.setAuthenticated({
                authenticated: false,
              })
            );
          return;
        }
        if (isTokenExpired(that.accessToken)) {
          console.log('Access token has expired.. Refreshing new');
          that.refreshAccessToken().subscribe();
        }
      }, 20000);
    });
  }

  refreshAccessToken(): Observable<{
    access_token: string;
    refresh_token: string;
    session_state: string;
  }> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new URLSearchParams();
    params.set('refresh_token', this.refreshToken);
    params.set('session_state', this.sessionState);
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        session_state: string;
      }>(`${this.apiUrl}refresh-token`, params.toString(), {
        headers: headers,
      })
      .pipe(
        tap(({ access_token, refresh_token, session_state }) => {
          this.setTokens(access_token, refresh_token, session_state);
        })
      );
  }

  loadProfile(): Observable<{
    user: User;
    livesRemaining: number;
    chanceRemaining: number;
  }> {
    return this.http
      .get<User>(
        `${this.apiUrl}profile`,

        { observe: 'response' }
      )
      .pipe(
        map((response) => {
          const aq = response.body;
          return {
            user: aq!,
            livesRemaining: Number(
              response.headers.get('QUIZ-LIFE-REMAINING') ?? 5
            ),
            chanceRemaining: Number(
              response.headers.get('QUIZ-CHANCE-REMAINING') ?? 4
            ),
          };
        })
      );
  }
  loadTransactions(): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}coin-history/unseen`
    );
  }
  // loadTransactions(): Observable<Transaction[]>{
  //   return this.http.get<Transaction[]>(this.jsonUrl)
  //  }
  acceptTransaction(id: number): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(
      `${environment.apiUrl}coin-history/seen/${id}`
    );
  }
  refillLives(): Observable<{
    livesRemaining: number;
    chanceRemaining: number;
    coin: number;
  }> {
    return this.http
      .get<any>(`${environment.apiUrl}lives/buy`, { observe: 'response' })
      .pipe(
        map((response) => {
          return {
            livesRemaining: Number(
              response.headers.get('QUIZ-LIFE-REMAINING') ?? 5
            ),
            chanceRemaining: Number(
              response.headers.get('QUIZ-CHANCE-REMAINING') ?? 4
            ),
            coin: Number(response.headers.get('COIN') ?? 5),
          };
        })
      );
  }
  signup(user: SignupInterface): Observable<{
    access_token: string;
    refresh_token: string;
    session_state: string;
  }> {
    const headers = new HttpHeaders().set('skip', 'true');
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        session_state: string;
      }>(`${this.apiUrl}register`, user, {
        headers: headers,
      })
      .pipe(
        tap(({ access_token, refresh_token, session_state }) => {
          this.setTokens(access_token, refresh_token, session_state);
        })
      );
  }
  updatePassword(
    oldPass: string,
    newPass: string
  ): Observable<{
    user: User;
    livesRemaining: number;
    chanceRemaining: number;
  }> {
    return this.http
      .post<User>(
        `${this.apiUrl}update-password`,
        {
          prevPassword: oldPass,
          newPassword: newPass,
        },
        { observe: 'response' }
      )
      .pipe(
        map((response) => {
          const aq = response.body;
          return {
            user: aq!,
            livesRemaining: Number(
              response.headers.get('QUIZ-LIFE-REMAINING') ?? 5
            ),
            chanceRemaining: Number(
              response.headers.get('QUIZ-CHANCE-REMAINING') ?? 4
            ),
          };
        })
      );
  }
  updatePhone(phone: string, secondaryEmail?: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .put<string>(
        `${this.apiUrl}users/baeuah-hsjhs`,
        { phone: phone, email: secondaryEmail },
        httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('HTTP PUT error:', error);
          return throwError(error); // Return the error for handling
        })
      );
  }

  forgotPassword(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    return this.http.post(
      `${this.apiUrl}forgot-password`,
      { email: email },
      httpOptions
    );
  }
  verifyEmail(otp: string) {
    return this.http.post(`${this.apiUrl}verify-email`, { otp: otp });
  }

  getOtp() {
    return this.http.post(`${this.apiUrl}send-otp`, null);
  }

  resetPassword(otp: string, newPassword: string, email: string) {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');
    return this.http.post(
      `${this.apiUrl}reset-password`,
      {
        otp: otp,
        newPassword: newPassword,
        email: email,
      },
      {
        headers: headers,
      }
    );
  }

  setTokens(accessToken: string, refreshToken: string, sessionState: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.sessionState = sessionState;
    const user = helper.decodeToken<User>(this.accessToken);
    this.store.dispatch(
      APP_ACTIONS.setTokens({
        accessToken: accessToken,
        refreshToken: refreshToken,
        sessionState: sessionState,
      })
    );
    this.store.dispatch(APP_ACTIONS.setUser({ user }));
  }
}
