import { Injectable, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TourService, IStepOption, } from 'ngx-ui-tour-md-menu';
import { filter } from 'rxjs/operators';

interface TourEvent {
  name: string;
  value: any; // We'll narrow this type when we use it
}
interface TourState {
  // Other properties...
  currentStep?: IStepOption; // Adjust based on actual properties
}

@Injectable({
  providedIn: 'root'
})
export class TourManagerService {
  private router = inject(Router);
  private tourService = inject(TourService);

  initializeTourNavigation() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.navigateIfRouteChanges();
    });

    this.tourService.events$.subscribe((event: TourEvent) => {
      if (event.name === 'next.step') {
        this.navigateToNextStep(event.value as IStepOption);
      }
    });
  }

  private navigateIfRouteChanges() {
    const status = this.tourService.getStatus() as unknown as {
      currentStep: any; step: IStepOption
}; // Adjust as necessary

    // Assuming 'currentStep' is a property in 'status'
    const currentStep = status.currentStep; // Adjust based on actual property

    if (currentStep && 'route' in currentStep && typeof currentStep.route === 'string' && currentStep.route !== this.router.url) {
        this.router.navigateByUrl(currentStep.route).catch(err => {
            console.error('Navigation error:', err);
        });
    }
}


  private navigateToNextStep(nextStep: IStepOption) {
    if (nextStep && 'route' in nextStep && typeof nextStep.route === 'string' && nextStep.route !== this.router.url) {
      this.router.navigateByUrl(nextStep.route).catch(err => {
        console.error('Navigation error:', err);
      });
    }
  }
}
