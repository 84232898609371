import {
  Action,
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
  Store,
} from '@ngrx/store';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import { inject } from '@angular/core';
import { EMPTY, catchError, map, of, switchMap, tap } from 'rxjs';
import { User } from 'src/app/signup/store/signup.store';
import { AuthService } from 'src/app/auth.service';
import { APP_ACTIONS } from 'src/app/store/app.store';

export interface UserState {
  transactions: Transaction[] | null;
  profile: User | null;
  error: string | null;
  loading: boolean;
  livesRemaining: number;
  chanceRemaining: number;
  currentScreen: ProfileScreen;
  coin: number;
  currentTransactionIndex: number; 
}
export type ProfileScreen = 'SUBSCRIPTION' | 'PROFILE';

export interface Transaction {
  id: number;
  transactionDate: string; 
  seen: boolean;
  message: string | null;
  amount: number;
  transactionType: string;
  issuer: string;
  coinFeature: string;
}
export interface ProfileChangePass {
  oldPassword: string;
  newPassword: string;
}

const initialState: UserState = {
  transactions: null,
  profile: null,
  error: null,
  loading: false,
  currentScreen: 'SUBSCRIPTION',
  livesRemaining: 5,
  chanceRemaining: 5,
  coin: 0,
  currentTransactionIndex: 0,
};

export const PROFILE_ACTIONS = createActionGroup({
  source: 'Profile API',
  events: {
    ChangeScreen: props<{ screen: ProfileScreen }>(),
    'Profile Load Request': emptyProps(),
    'Profile Load Success': props<{
      profile: User;
      livesRemaining: number;
      chanceRemaining: number;
      coin: number;
    }>(),
    'Change Password Request': props<{
      password: ProfileChangePass;
    }>(),
    'Profile Load Failure': props<{ error: string }>(),
    'Life refill Request': emptyProps(),
    'Life refill Success': props<{
      livesRemaining: number;
      chanceRemaining: number;
      coin: number;
    }>(),
    'Life refill failure': props<{ error: string }>(),
    'Transaction Load Request': emptyProps(),
    'Transaction Load Success': props<{
      transactions: Transaction[];
    }>(),
    'Transaction Load Failure': props<{ error: string }>(),
    'Accept Transaction Request': props<{ id: number }>(),
    'Accept Transaction Success': props<{ transaction: Transaction[] }>(),
    'Accept Transaction Failure': props<{ error: string }>(),
    'change Next Transaction': emptyProps(),
  },
});

const profileReducer = createReducer(
  initialState,
  on(PROFILE_ACTIONS.changeScreen, (state, action) => {
    return { ...state, currentScreen: action.screen };
  }),
  on(PROFILE_ACTIONS.profileLoadFailure, (state, action) => {
    return { ...state, error: action.error };
  }),
  on(PROFILE_ACTIONS.profileLoadSuccess, (state, action) => {
    return { ...state,
       profile: action.profile,
      livesRemaining: action.livesRemaining,
      chanceRemaining: action.chanceRemaining,
      coin: action.coin
     };
  }),
  on(PROFILE_ACTIONS.lifeRefillSuccess, (state, action) => {
    return { ...state,    
    livesRemaining: action.livesRemaining,
    chanceRemaining: action.chanceRemaining,};
  }),

  on(PROFILE_ACTIONS.lifeRefillFailure, (state, action) => {
    return {...state,
       error: action.error,
     };
  }),
  on(PROFILE_ACTIONS.transactionLoadRequest, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(PROFILE_ACTIONS.transactionLoadSuccess, (state, action) => {
    return {
      ...state,
      transactions: action.transactions,
      loading: false,
    };
  }),
  on(PROFILE_ACTIONS.transactionLoadFailure, (state, action) => {
    return { ...state, loading: false, error: action.error };
  }),
  on(PROFILE_ACTIONS.acceptTransactionRequest, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(PROFILE_ACTIONS.acceptTransactionSuccess, (state, action) => {
    return {
       ...state,
       transaction: action.transaction,
        loading: false 
      };
  }),
  on(PROFILE_ACTIONS.acceptTransactionFailure, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(PROFILE_ACTIONS.changeNextTransaction, (state, action)=>{
    return {
      ...state,
      currentTransactionIndex: state.currentTransactionIndex+1
    }
  })
);

export const profileFeature = createFeature({
  name: 'Profile',
  reducer: profileReducer,
});


export const { selectProfile, selectError,selectCurrentScreen, selectLoading, selectProfileState,selectTransactions, selectCurrentTransactionIndex } = profileFeature;
export const selectUserPhone = createSelector(
  selectProfile,
  (p)=>p?.phone
)
export const selectCategoryId = createSelector(
  selectProfile,
  (p)=>p?.category?.id
)
export const selectCurrentTransaction = createSelector(
  selectTransactions,selectCurrentTransactionIndex,
  (transactions, currentTransactionIndex) => {
    if(transactions) {
      return transactions[currentTransactionIndex]
    }
    return null;
  }
)

const profileReqEffect = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(PROFILE_ACTIONS.profileLoadRequest),
      switchMap(() =>
        authService.loadProfile().pipe(
          catchError((error) => {
            PROFILE_ACTIONS.profileLoadFailure({
              error: error,
            });
            return EMPTY;
          }),
          map((data) =>
            PROFILE_ACTIONS.profileLoadSuccess({
              profile: data.user,
              livesRemaining: data.livesRemaining,
              chanceRemaining: data.chanceRemaining,
              coin: data.user.coins ?? 0,
            })
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
const lifeRefillReqEffects = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(PROFILE_ACTIONS.lifeRefillRequest),
      switchMap(() =>
        authService.refillLives().pipe(
          catchError((error) => {
            PROFILE_ACTIONS.lifeRefillFailure({
              error: error,
            });
            return EMPTY;
          }),
          map((data) =>
            PROFILE_ACTIONS.lifeRefillSuccess({
              livesRemaining: data.livesRemaining,
              chanceRemaining: data.chanceRemaining,
              coin: data.coin
            })
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
const liveRefillSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(PROFILE_ACTIONS.lifeRefillSuccess),
          map((data) =>
            APP_ACTIONS.updateLives({
              livesRemaining: data.livesRemaining,
              chanceRemaining: data.chanceRemaining,
          
            })
          )
        )
  },
  {
    functional: true,
  }
);
const liveRefillSuccessCoinEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(PROFILE_ACTIONS.lifeRefillSuccess),
          map((data) =>
            APP_ACTIONS.updateCoin({
              coin: data.coin
          
            })
          )
        )
  },
  {
    functional: true,
  }
);
const profileLoadSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(PROFILE_ACTIONS.profileLoadSuccess),
          map((data) =>
            APP_ACTIONS.updateLives({
              livesRemaining: data.livesRemaining,
              chanceRemaining: data.chanceRemaining
            })
          )
        )
    
  },
  {
    functional: true,
  }
);
const UpdateCoinSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(PROFILE_ACTIONS.profileLoadSuccess),
          map((data) =>
            APP_ACTIONS.updateCoin({
              coin: data.coin,
            })
          )
        )
    
  },
  {
    functional: true,
  }
);
const transactionLoadEffect = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(PROFILE_ACTIONS.transactionLoadRequest),
      switchMap(() =>
        authService.loadTransactions().pipe(
          catchError((error) => {
            PROFILE_ACTIONS.transactionLoadFailure({ error: error });
            return EMPTY;
          }),
          map((data) =>
            PROFILE_ACTIONS.transactionLoadSuccess({
              transactions: data,
            })
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
const acceptTransactionEffect = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(PROFILE_ACTIONS.acceptTransactionRequest),
      switchMap(({ id }) =>
        authService.acceptTransaction(id).pipe(
          map((transaction) =>
            PROFILE_ACTIONS.acceptTransactionSuccess({ transaction:transaction })
          ),
          catchError((error) =>
            of(PROFILE_ACTIONS.acceptTransactionFailure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);
const acceptTransactionSuccessEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(PROFILE_ACTIONS.acceptTransactionSuccess),
      map(() => PROFILE_ACTIONS.changeNextTransaction())
    );
  },
  {
    functional: true,
  }
);


const changePassEffect = createEffect(
  (action$ = inject(Actions)) => {
    const authService = inject(AuthService);
    return action$.pipe(
      ofType(PROFILE_ACTIONS.changePasswordRequest),
      switchMap(({ password }) =>
        authService
          .updatePassword(password.oldPassword, password.newPassword)
          .pipe(
            map((page) =>
              PROFILE_ACTIONS.profileLoadSuccess({
                profile: page.user,
                livesRemaining: page.livesRemaining,
                chanceRemaining:page.chanceRemaining,
                coin: page.user.coins ?? 0,
              })
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);
export const profileChangePassEffects = provideEffects({
  profileLoadSuccessEffect,
  liveRefillSuccessEffect,
  lifeRefillReqEffects,
  changePassEffect,
  profileReqEffect,
  transactionLoadEffect,
   acceptTransactionEffect,
  acceptTransactionSuccessEffect,
  UpdateCoinSuccessEffect,
  liveRefillSuccessCoinEffect
});
