import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import { inject } from '@angular/core';
import { EMPTY, catchError, map, switchMap } from 'rxjs';
import { ConstantService } from '../constant.service';

export interface SubscriptionDetails {
  details: string;
  dailyFreeMockups: number;
  dailyFreeCoins: number;
  lives: string;
  cost: number;
  freeCoins: number;
  motto: string;
}

export interface SubscriptionPlan {
  BASIC: SubscriptionDetails;
  STANDARD: SubscriptionDetails;
  PREMIUM: SubscriptionDetails;
}

export interface ConstantState {
  coinFeatures: { [key: string]: number };
  coinPackages: {
    [key: string]: { discount: number; coins: number; price: number };
  };
  faculties: { [key: string]: any[] | any[] };
  subscriptions: SubscriptionPlan;
  version: string;
  shouldUpdate: boolean;
  serverTime: number;
  error: string | null;
  loading: boolean;
}

const initialState: ConstantState = {
  coinFeatures: {},
  coinPackages: {},
  faculties: {},
  subscriptions: {
    BASIC: {
      freeCoins: 0,
      details: '',
      cost: 0,
      lives: '',
      dailyFreeCoins: 0,
      dailyFreeMockups: 0,
      motto: '',
    },
    STANDARD: {
      freeCoins: 0,
      details: '',
      cost: 0,
      lives: '',
      motto: '',
      dailyFreeCoins: 0,
      dailyFreeMockups: 0,
    },
    PREMIUM: {
      freeCoins: 0,
      details: '',
      cost: 0,
      lives: '',
      motto: '',
      dailyFreeCoins: 0,
      dailyFreeMockups: 0,
    },
  },
  serverTime: 0,
  version: '',
  shouldUpdate: false,
  error: null,
  loading: false,
};

export const CONSTANT_ACTIONS = createActionGroup({
  source: 'Constant API',
  events: {
    'Load Constant Request': emptyProps(),
    'Load Constant Success': props<{
      coinFeatures: Record<string, number>;
      coinPackages: Record<
        string,
        { discount: number; coins: number; price: number }
      >;
      faculties: Record<string, string[]>;
      subscription: SubscriptionPlan; // This should match the structure of your state
      serverTime: number;
      version: string;
      shouldUpdate: boolean;
    }>(),
    'Load Constant Failure': props<{ error: string }>(),
  },
});

const constantReducer = createReducer(
  initialState,
  on(CONSTANT_ACTIONS.loadConstantRequest, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(CONSTANT_ACTIONS.loadConstantSuccess, (state, action) => {
    return {
      ...state,
      coinFeatures: action.coinFeatures,
      coinPackages: action.coinPackages,
      faculties: action.faculties,
      subscriptions: action.subscription,
      serverTime: action.serverTime,
      loading: false,
    };
  }),
  on(CONSTANT_ACTIONS.loadConstantFailure, (state, action) => {
    return { ...state, loading: false, error: action.error };
  })
);

export const constantFeature = createFeature({
  name: 'Constant',
  reducer: constantReducer,
});

export const {
  selectConstantState,
  selectCoinFeatures,
  selectCoinPackages,
  selectFaculties,
  selectSubscriptions,
  selectServerTime,
  selectError,
  selectLoading,
  selectVersion,
  selectShouldUpdate,
} = constantFeature;

export const constantLoadEffect = createEffect(
  (action$ = inject(Actions)) => {
    const constantService = inject(ConstantService);
    return action$.pipe(
      ofType(CONSTANT_ACTIONS.loadConstantRequest),
      switchMap(() =>
        constantService.loadConstants().pipe(
          catchError((error) => {
            CONSTANT_ACTIONS.loadConstantFailure({ error });
            return EMPTY;
          }),
          map((data) =>
            CONSTANT_ACTIONS.loadConstantSuccess({
              coinFeatures: data.coinFeatures,
              coinPackages: data.coinPackages,
              faculties: data.faculties,
              subscription: data.subscriptions,
              serverTime: data.serverTime,
              version: data.version,
              shouldUpdate: data.shouldUpdate,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const constantEffects = provideEffects({
  constantLoadEffect,
});
