import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType, provideEffects } from '@ngrx/effects';
import {
  createActionGroup,
  props,
  createReducer,
  on,
  createFeature,
  emptyProps,
  Store,
} from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, tap, withLatestFrom } from 'rxjs';
import { User } from '../signup/store/signup.store';
import { helper, isTokenExpired } from '../utils/Utils';
import { hideBanner, showBannerAd } from '../admob-config';
import { Location } from '@angular/common';
import { BannerAdPosition } from '@capacitor-community/admob';
import { AD_OPTIONS } from 'src/main';
import { HeaderBg } from '../app.component';
import { HotToastService } from '@ngxpert/hot-toast';
import { access } from 'fs';

export interface AppError {
  status: number;
  message: string;
}

export interface AppState {
  loading: boolean;
  authenticated: boolean;
  darkMode: boolean;
  error: AppError | null;
  refreshToken: string | null;
  accessToken: string | null;
  sessionState: string | null;
  // splashShown: boolean;
  user: User | null;
  showBannerAd: boolean;
  sound: boolean;
  headerBg: HeaderBg;
  online: boolean;
  livesRemaining: number;
  chanceRemaining: number;
  coin: number;
  messageCount: number;
  notificationUpdateCount: number;
}

const initialState: AppState = {
  loading: false,
  authenticated: !isTokenExpired(localStorage.getItem('refresh_token')),
  darkMode: false,
  error: null,
  accessToken: localStorage.getItem('access_token'),
  refreshToken: localStorage.getItem('refresh_token'),
  sessionState: localStorage.getItem('session_state'),
  // splashShown: !!localStorage.getItem('splashShown'),
  user: helper.decodeToken<User>(localStorage.getItem('access_token') ?? ''),
  showBannerAd: false,
  sound: localStorage.getItem('sound') != 'off',
  headerBg: 'bg-white',
  online: true,
  livesRemaining: 0,
  chanceRemaining: 0,
  coin: 0,
  messageCount: 0,
  notificationUpdateCount: 0,

};

export const APP_ACTIONS = createActionGroup({
  source: 'App API',
  events: {
    'Set Authenticated': props<{
      authenticated: boolean;
      token?: string;
    }>(),
    'Set Loading': props<{ loading: boolean }>(),
    'Show Banner Ad': props<{ show: boolean }>(),
    'Set Tokens': props<{
      accessToken: string;
      refreshToken: string;
      sessionState: string;
    }>(),
    'Set User': props<{
      user: User | null;
    }>(),
    'Update lives': props<{livesRemaining: number, chanceRemaining: number,}>(),
    'Update coin': props<{coin: number}>(),
    'Toggle Dark Mode': emptyProps(),
    'Message Count' : emptyProps(),
    'Message Count Reset' : emptyProps(),
    'Notification Count' : emptyProps(),
    'Notification Count Reset' : emptyProps(),
    'Set Dark Mode': props<{ darkMode: boolean }>(),
    'Set App Error': props<{ error: AppError }>(),
    'Set Online ': props<{ online: boolean }>(),
    'Toggle Sound': emptyProps(),
    'change header bg': props<{ headerBg: HeaderBg }>(),
  },
});

const appReducer = createReducer(
  initialState,
  on(APP_ACTIONS.setAuthenticated, (state, action) => {
    return {
      ...state,
      authenticated: action.authenticated,
    };
  }),
  on(APP_ACTIONS.setTokens, (state, action) => {
    return {
      ...state,
      accessToken: action.accessToken,
      refreshToken: action.refreshToken,
      sessionState: action.sessionState,
    };
  }),
  on(APP_ACTIONS.updateLives,(state , action) =>{
    return {
      ...state, 
      livesRemaining: action.livesRemaining,
      chanceRemaining: action.chanceRemaining,
    }
  }),
on(APP_ACTIONS.updateCoin,(state, action) =>{
  return {
    ...state, 
    coin: action.coin,
  }
}),
on(APP_ACTIONS.messageCount,(state, action) =>{
  return {
    ...state, 
     messageCount : state.messageCount+1
  }
}),
on(APP_ACTIONS.notificationCount,(state, action) =>{
  return {
    ...state, 
     notificationUpdateCount : state.notificationUpdateCount+1
  }
}),
on(APP_ACTIONS.notificationCountReset,(state, action) =>{
  return {
    ...state, 
     notificationUpdateCount : 0
  }
}),
on(APP_ACTIONS.messageCountReset,(state, action) =>{
  return {
    ...state, 
     messageCount : 0
  }
}),
  on(APP_ACTIONS.setUser, (state, action) => {
    return { ...state, user: action.user };
  }),
  on(APP_ACTIONS.setLoading, (state, action) => {
    return { ...state, loading: action.loading };
  }),
  on(APP_ACTIONS.toggleDarkMode, (state) => {
    return { ...state, darkMode: !state.darkMode };
  }),
  on(APP_ACTIONS.setAppError, (state, action) => {
    return { ...state, error: action.error };
  }),
  on(APP_ACTIONS.showBannerAd, (state, action) => {
    return { ...state, showBannerAd: action.show };
  }),
  on(APP_ACTIONS.toggleSound, (state) => {
    return { ...state, sound: !state.sound };
  }),
  on(APP_ACTIONS.changeHeaderBg, (state, action) => {
    return {
      ...state,
      headerBg: action.headerBg,
    
    };
  }),
  on(APP_ACTIONS.setOnline, (state, action) => {
    return {
      ...state,
      online: action.online,
    };
  })
);

export const appFeature = createFeature({
  name: 'app',
  reducer: appReducer,
});

export const {
  selectLoading,
  selectDarkMode,
  selectAccessToken,
  selectShowBannerAd,
  selectUser: selectLoggedInUser,
  selectAuthenticated,
  selectSound,
  selectHeaderBg,
  selectOnline,
  selectLivesRemaining,
  selectChanceRemaining,
  selectCoin,
  selectMessageCount,
  selectNotificationUpdateCount
} = appFeature;



const loadingEffect = createEffect(
  (action$ = inject(Actions)) => {
    const loadingService = inject(NgxUiLoaderService);
    return action$.pipe(
      ofType(APP_ACTIONS.setLoading),
      tap(({ loading }) => {
        if (loading) loadingService.start();
        else loadingService.stop();
      })
    );
  },
  { dispatch: false, functional: true }
);

const authenticatedEffect = createEffect(
  (action$ = inject(Actions)) => {
    const router = inject(Router);
    const route = inject(ActivatedRoute);
    const location = inject(Location);
    return action$.pipe(
      ofType(APP_ACTIONS.setAuthenticated),
      withLatestFrom(route.queryParams.pipe(map((qp) => qp['redirectUrl']))),
      tap(([{ authenticated }, redirectUrl]) => {
        if (authenticated) {
          if (
            redirectUrl &&
            (redirectUrl.includes('signup') ||
              redirectUrl.includes('forgot-password') ||
              redirectUrl.includes('verify-email') ||
              redirectUrl.includes('home/branches'))
          )
            redirectUrl = null;
          if (redirectUrl) {
            router.navigateByUrl(redirectUrl);
            location.replaceState(redirectUrl);
          } else router.navigate(['/home/categories'], { replaceUrl: true });
        } else {
          if (router.url !== '/') redirectUrl = router.url;

          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          if (redirectUrl) {
            router.navigate(['/login'], {
              queryParams: {
                redirectUrl: redirectUrl,
              },
            });
          } else router.navigate(['/login']);
        }
      })
    );
  },
  { dispatch: false, functional: true }
);

const appErrorEffect = createEffect(
  (action$ = inject(Actions)) => {
    const store = inject(Store);
    const toast = inject(HotToastService);
    return action$.pipe(
      ofType(APP_ACTIONS.setAppError),
      tap(({ error }) => {
        switch (error.status) {
          case 401:
            toast.error(error.message ,{
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });
            if (error.message.startsWith('Session has expired')) {
              store.dispatch(
                APP_ACTIONS.setAuthenticated({ authenticated: false })
              );
            }
            break;
          case 400:
            toast.warning(error.message,{
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });
            break;
          case 409:
            toast.error(error.message,{
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });
            break;
          case 500:
            toast.error('Internal Server Error',{
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });
            break;
          default:
            toast.show('Something went wrong..',{
              dismissible: true,
              autoClose: true,
              duration: 3000, // Duration in milliseconds
            });;
        }
      })
    );
  },
  { dispatch: false, functional: true }
);
const bannerAdEffect = createEffect(
  (action$ = inject(Actions)) => {
    const adOptions = inject(AD_OPTIONS);
    return action$.pipe(
      ofType(APP_ACTIONS.showBannerAd),
      tap(({ show }) => {
        /**
        if (show)
          showBannerAd({
            ...adOptions,
            adId: adOptions.bannerAdId,
            position: BannerAdPosition.BOTTOM_CENTER,
          });
        else hideBanner();
          */
      })
    );
  },
  { dispatch: false, functional: true }
);
const soundChangeEffect = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(APP_ACTIONS.toggleSound),
      tap((soundOn) => {
        localStorage.setItem('sound', soundOn ? 'on' : 'off');
      })
    );
  },
  { dispatch: false, functional: true }
);

export const AppEffects = provideEffects(
  { loadingEffect },
  { appErrorEffect },
  { authenticatedEffect },
  { bannerAdEffect },
  { soundChangeEffect }
);
