import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { IonicModule, isPlatform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { Store } from '@ngrx/store';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { AuthService } from './auth.service';
import { App, App as CapacitorApp } from '@capacitor/app';
import { IS_INTERSTITIAL_AD_SHOWING, initializeAd } from './admob-config';
import {
  APP_ACTIONS,
  selectHeaderBg,
  selectLoading,
  selectLoggedInUser,
  selectMessageCount,
  selectOnline,
} from './store/app.store';
import { isTokenExpired } from './utils/Utils';
import { AudioService } from './audio.service';
import { AsyncPipe, Location, NgClass, NgIf, NgStyle } from '@angular/common';
import { Network } from '@capacitor/network';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AD_OPTIONS } from 'src/main';

import { NoInternetComponent } from './no-internet/no-internet.component';
import { filter, map, shareReplay, startWith, Subscription, tap } from 'rxjs';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { performImmediateUpdate } from './app-update-utils';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { environment } from 'src/environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { HotToastService } from '@ngxpert/hot-toast';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { TourManagerService } from './tourManagerService';
import { PROFILE_ACTIONS, selectProfile } from './profile/store/profile.store';
import {
  NOTIFICATION_ACTIONS,
  selectCurrentPriorityIndex,
  selectCurrentPriorityNotification,
  selectNotificationCount,
  selectNotifications,
} from './notification/notification.store';
import { EventSourceService } from './event-source.service';
import { ForumService } from './forum.service';
import { CONSTANT_ACTIONS, selectShouldUpdate, selectVersion } from './store/constant.store';
import { ConstantService } from './constant.service';
import { MatDialog } from '@angular/material/dialog';
register();
export type HeaderBg = 'bg-white' | 'bg-quiz' | 'bg-primary';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [
        IonicModule,
        CustomToastComponent,
        NgIf,
        MatProgressBar,
        NgStyle,
        NgClass,
        NoInternetComponent,
        AsyncPipe,
        RouterOutlet,
        RouterLink,
    ],
    providers: [AudioService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {

  readonly store = inject(Store);
  readonly router = inject(Router);
  readonly cdr = inject(ChangeDetectorRef);
  readonly dialog = inject(MatDialog);
  readonly toastService = inject(HotToastService);
  readonly isOnline = this.store.selectSignal(selectOnline);
  readonly loading = this.store.selectSignal(selectLoading);
  readonly headerBg = this.store.selectSignal(selectHeaderBg);
  readonly authService = inject(AuthService);
  readonly loggedInUser$ = this.store.selectSignal(selectProfile);
  readonly token$ = this.store.selectSignal(selectLoggedInUser);
  readonly adOptions = inject(AD_OPTIONS);
  readonly location = inject(Location);
  @ViewChild('updateDialog') updateDialog!: TemplateRef<any>;
  readonly messageCount = this.store.selectSignal(selectMessageCount);
   messageSubscription: Subscription | undefined;
   private readonly version = environment.version;
   readonly version$ = this.store.selectSignal(selectVersion);
   readonly shouldUpdate$ = signal(false);
    readonly notificationsCount$ = this.store.selectSignal(
    selectNotificationCount
  );

  private readonly routerEvents$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map(({ url }) => url),
    shareReplay()
  );

  readonly activeRoute = toSignal(this.routerEvents$);
  readonly showNavBar = computed(() => {
    const url = this.activeRoute() as string;
    if (!url) return false;
    return (
      url.startsWith('/home/categories') ||
      url.startsWith('/home/profile') ||
      url.startsWith('/home/achievements') ||
      url.startsWith('/home/mockup-history') ||
      url.startsWith('/home/settings')

    );
  });
  async isUpdateAvailable() {
    const updateAvailable = await AppUpdate.getAppUpdateInfo();
    return (
      updateAvailable.updateAvailability ===
      AppUpdateAvailability.UPDATE_AVAILABLE
    );
  }
 platform = Capacitor.getPlatform();

  hideStatusBar$ = toSignal(
    this.routerEvents$.pipe(
      tap((url) => {
      }),
      map((url) => {
        return (
         url.startsWith('/login') ||
             url.startsWith('/signup') ||
             url.startsWith('/forgot-password') ||
            url.startsWith('/home/branches')
      )
      })
    )
  );
  bundle!: BundleInfo;
  constructor(tourManagerService: TourManagerService,
   ) {
    this.constantService.loadConstants()
    .subscribe(constant=>{
     if(constant.version > environment.version){
      this.dialog.open(this.updateDialog, {
        disableClose: constant.shouldUpdate,
        panelClass: 'update-dialog',
        height: '100%',
        width: '100%'
      });
      this.shouldUpdate$.set(constant.shouldUpdate);
     }
    })
    tourManagerService.initializeTourNavigation();

    this.hideStatusBar();
    if (Capacitor.getPlatform() !== 'web') {
      App.addListener('appStateChange', async (state) => {
        const version = await fetch(environment.liveUpdateManifestUrl);
        if (!version.ok) {
          console.error('Error while getting update manifest ');
          return;
        }
        type Manifest = {
          latestVersion: string;
          whatsNew: string[];
          updateUrl: string;
        };
        const manifest = (await version.json()) as Manifest;
        const currentLatestVersion = manifest.latestVersion;
        const currentAppVersion = localStorage.getItem('app_version') || '';

        if (currentAppVersion !== currentLatestVersion) {
          if (state.isActive) {
            this.bundle = await CapacitorUpdater.download({
              url: manifest.updateUrl,
              version: currentLatestVersion,
            });
          }
          if (!state.isActive && this.bundle.version) {
            try {
              //this will reload new version in next launch
              await CapacitorUpdater.next(this.bundle);
              await CapacitorUpdater.notifyAppReady();
              localStorage.setItem('app_version', currentLatestVersion);
              alert("What's changed \n " + manifest.whatsNew.join('\n'));
            } catch (e) {
              console.error('error while updating ', e);
            }
          }
        }
      });
    }
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#232C41' });
    }

    effect(() => {
      const loggedInUser = this.loggedInUser$();
      const token = this.token$();
      if (loggedInUser && token) {
          if (!token.email_verified || (!loggedInUser.secondaryEmailVerified && loggedInUser.secondaryEmail != null)) {
            this.router.navigate(['/verify-email']);
          } else if (loggedInUser.category ==null) {
            this.router.navigate(['/home/branches']);
          }
      }
    });

  }
  constantService = inject(ConstantService);
  ngAfterViewInit(): void {
  }
  isAndroid(): boolean {
    return isPlatform('android');
  }
  async hideStatusBar() {
    await StatusBar.hide();
    StatusBar.setOverlaysWebView({overlay: true})

}

  ngOnInit() {
    this.store.dispatch(PROFILE_ACTIONS.profileLoadRequest());
    this.updateApp()
    this.routerEvents$
      .pipe(
        tap((url) => {
          let bg: HeaderBg = 'bg-primary';
          if (
            url.startsWith('/home/quiz/sets') ||
            url.startsWith('/home/mock-up') ||
            url.startsWith('/home/question-sets') ||
            url.startsWith('/home/battle-quiz')
          ) {
            bg = 'bg-quiz';
          } else if (
            url.startsWith('/home/aboutUs') ||
            url.startsWith('/home/help-support')
          ) {
            bg = 'bg-quiz';
          } else if(url.startsWith('/home/quiz/questions')
        ) {
            bg = 'bg-primary';
          }

          this.store.dispatch(APP_ACTIONS.changeHeaderBg({ headerBg: bg }));
        })
      )
      .subscribe();
    Network.addListener(
      'networkStatusChange',
      ({ connected, connectionType }) => {
        if (environment.appUrl.startsWith('http://localhost')) return;
        this.store.dispatch(APP_ACTIONS.setOnline({ online: connected }));
        this.cdr.detectChanges();
      }
    );

    this.store.dispatch(
      APP_ACTIONS.setAuthenticated({
        authenticated: !isTokenExpired(localStorage.getItem('access_token')),
      })
    );
    CapacitorApp.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        const result = await AppUpdate.getAppUpdateInfo();

        // If an update is still available and immediate update is allowed
        if (
          result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE &&
          result.immediateUpdateAllowed
        ) {
          try {
            await AppUpdate.performImmediateUpdate();
          } catch (e) {
            console.error('Immediate update failed', e);
          }
        }
      }
    });

    CapacitorApp.addListener('backButton', (e: { canGoBack: any }) => {
      const url = this.router.url;
      if (
        IS_INTERSTITIAL_AD_SHOWING ||
        url.startsWith('/home/mock-up') ||
        url.startsWith('/home/quiz/questions')
      ) {
        console.info('not allowed to go back from this page');
        return;
      }
      const canGoBack =
        e.canGoBack && url !== '/home/categories' && url != '/login';
      if (!canGoBack) {
        if (confirm('Are you sure you want to exit the app ?')) {
          CapacitorApp.exitApp();
        }
      } else {
        this.location.back();
      }
    });
    initializeAd();
    /**
    showBannerAd({
      ...this.adOptions,
      adId: this.adOptions.bannerAdId,
      position: BannerAdPosition.BOTTOM_CENTER,
    });
    */
    // this.store.dispatch(NOTIFICATION_ACTIONS.updateNotificationCountRequest());
    // this.fs
    // .subscribeToForum(['MESSAGE'], ()=>{} )
    // .subscribe((e) => {
    //   console.log("msg in app comp from forum service ", e);
    // });

    // this.messageSubscription = this.sseService.getMessageStream().subscribe({
    //   next: (message) => {
    //     console.log('Received message:', message); // Log the message structure
    //     const url = this.router.url;
    //     if (message.data) {
    //       // Log if message.data is truthy
    //       const forJson = message.data;
    //       let forum;
    //       try {
    //         forum = typeof forJson === 'string' ? JSON.parse(forJson) : forJson;
    //       } catch (e) {
    //         console.error('Error parsing event data:', e);
    //         this.toastService.error('Error processing message.');
    //         return;
    //       }
    //       console.log(forum.firstName , forum.lastName ,forum.message);

    //       // Show toast notification for new messages

    //       if(this.loggedInUser$()?.email!= forum.email  && !url.startsWith('/home/forum')){

    //         this.toastService.info(` ${forum.firstName}  ${forum.lastName}:  ${forum.message}`, {
    //           dismissible: true,
    //           autoClose: true,
    //         });
    //       }

    //       // Update the message count
    //       this.messageCount += 1;
    //       console.log('Updated message count:', this.messageCount); // Log the count

    //       // If you need manual change detection, check if it’s necessary:
    //       this.cdr.detectChanges();
    //     } else {
    //       console.log('No message data received');
    //     }
    //   },
    //   error: (error) => {
    //     console.error('Error receiving message:', error);
    //   },
    //   complete: () => {
    //     console.log('SSE subscription completed');
    //   },
    // });



  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed to avoid memory leaks
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
  async checkForUpdateAndBlock() {
    const result = await AppUpdate.getAppUpdateInfo();

    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      if (result.immediateUpdateAllowed) {
        await this.showForceUpdateModal();
      }
    }
  }
  async showForceUpdateModal() {
    // Show a modal blocking the app until the update is performed
    const shouldUpdate = await this.showUpdateDialog();

    if (shouldUpdate) {
      try {
        await AppUpdate.performImmediateUpdate();
      } catch (e) {
        console.error('Immediate update failed', e);
        this.showForceUpdateModal(); // Retry until the update is completed
      }
    } else {
      CapacitorApp.exitApp(); // Exit the app if the user refuses to update
    }
  }
  async updateApp() {

    performImmediateUpdate();
  }

  checkForUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      // An update is available
      if (result.immediateUpdateAllowed) {
        // Show a dialog to prompt the user to update
        const shouldUpdate = await this.showUpdateDialog();

        if (shouldUpdate) {
          // Perform an immediate update
          await AppUpdate.performImmediateUpdate();
        }
      } else if (result.flexibleUpdateAllowed) {
        // Start a flexible update
        await AppUpdate.startFlexibleUpdate();
      }
    }
  };
  openStore() {
    window.location.href = 'https://www.yashiacademy.com';
  }
  showUpdateDialog = async () => {
    // Implement your own dialog UI here
    // Return true if the user wants to update, false otherwise
    // For example, using the built-in confirm dialog:
    return confirm(
      'A new version of the app is available. Do you want to update now?'
    );
  };
  reloadApp(): void {
    window.location.reload();
  }

  closeDialog(dialog : TemplateRef<any>){
    this.dialog.closeAll()

  }
}
