<div class="max-w-[1280px] mx-auto">
  <!-- <div
    *ngIf="!isAndroid()"
    class="h-[56px] w-full"
    [ngClass]="headerBg()"
  ></div> -->
@if(!hideStatusBar$()){
  <div
  *ngIf="!isAndroid()"
  class="h-[15px] w-full"
  [ngClass]="headerBg()"
></div>
}
  <div *ngIf="loading()" class="w-full fixed top-0 z-[1000]">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <app-no-internet class="z-[10001]" *ngIf="!isOnline()"></app-no-internet>

  <div
    [ngStyle]="
      loading() || !isOnline()
        ? { 'pointer-events': 'none', filter: 'blur(1px)' }
        : {}
    "
  >
    <router-outlet></router-outlet>
  </div>
</div>

@if((showNavBar())){
<div
  class="fixed bottom-0 w-full h-[80px] flex justify-center place-items-center bg-gradient-to-r from-[#495674] to-[#3D4558]"
>
  <div
    class="w-full bg-transparent h-fit text-white flex place-items-end justify-evenly overflow-x-scroll [&>*>*:nth-child(even)]:hidden xs:[&>*>*:nth-child(even)]:block text-xs sm:text-sm"
  >
    <a
      routerLink="/home/categories"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img class="h-[30px] w-[34px]"
        src="assets/bottomnav/home{{
          activeRoute() === '/home/categories' ? '_selected' : ''
        }}.png"
        alt="home"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/categories'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Home</span
      >
    </a>
    <a
      routerLink="/home/achievements"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img class="h-[30px] w-[30px]"
        src="assets/bottomnav/achievements{{
          activeRoute() === '/home/achievements' ? '_selected' : ''
        }}.png"
        alt="achievements"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/achievements'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Achievements</span
      >
    </a>

    <a
      routerLink="/home/forum"
      class="flex relative flex-col justify-center place-items-center gap-1"
    >
      <img class="h-[30px] w-[34px] " src="assets/bottomnav/forum.png" alt="forum" />
      <span class=" absolute top-0 right-0 place-content-center text-center rounded-full font-semibold text-[12px] h-5 w-5 bg-red-700" 
      *ngIf="(notificationsCount$().FORUM + messageCount()) > 0" >{{notificationsCount$().FORUM + messageCount()}}</span>
      <span>Discussions</span>
    </a>

    <a
      routerLink="/home/battle-quiz"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img class="h-[30px] w-[38px]" src="assets/bottomnav/battle_blaze.png" alt="battleBlaze" />
      <span>Battle Blaze</span>
    </a>

    <a
      routerLink="/home/profile"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img class="h-[30px] w-[30px]"
        src="assets/bottomnav/profile{{
          activeRoute() === '/home/profile' ? '_selected' : ''
        }}.png"
        alt="profile"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/profile'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Profile</span
      >
    </a>
  </div>
</div>
}
<ng-template  class= "flex flex-col text-white gap-16 w-full rounded-none  dialog-frame2  " #updateDialog >
  <div  class="flex flex-col p-4 gap-10  mt-10">
    <img class="w-[316px] h-[282px] self-center" src="../assets/images/update.gif" alt="">
    <div class="text-[32px] text-center text-white font-extrabold mt-5">Update Your App</div>
    <div
      class=" text-center text-[20px] leading-relaxed overflow-y-scroll font-semibold text-white"
    >
    To enjoy our newest app features tap the button below </div>
 
  </div>
  <div class="flex flex-col justify-between mt-10">

    <button
    (click)="openStore()"
    class="bg-[#FE565E] h-[60px] p-2 mx-auto w-[276px] text-white self-center place-items-center text-[20px] font-bold rounded-[10px]"
     >
       Update Now
  </button>
  @if(!shouldUpdate$()){

    <button 
    (click)="closeDialog(updateDialog)"
    class="p-2 mx-auto w-[135px] text-white self-center place-items-center text-[20px]  font-bold rounded-[10px]"
     >
       Skip
    </button>
  }
  </div>

</ng-template>
