import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterDTO, PageDTO } from './app.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractService<Entity> {
  abstract serviceName(): string;
  API_URL = environment.apiUrl;

  constructor(protected http: HttpClient) {
    this.init();
  }
  protected init(): void {}

  protected get BASE_URL(): string {
    return this.API_URL + this.serviceName();
  }

  public filterData(
    filter: FilterDTO,
    headers?: HttpHeaders
  ): Observable<PageDTO<Entity>> {
    return this.http.post<PageDTO<Entity>>(`${this.BASE_URL}/filter`, filter, {
      headers: headers,
    });
  }

  public getById(id: number, headers?: HttpHeaders): Observable<Entity> {
    return this.http.get<Entity>(`${this.BASE_URL}/${id}`, {
      headers: headers,
    });
  }

  public create(t: Entity, headers?: HttpHeaders): Observable<Entity> {
    return this.http.post<Entity>(`${this.BASE_URL}`, t, { headers: headers });
  }

  public update(
    id: number,
    t: Entity,
    headers?: HttpHeaders
  ): Observable<Entity> {
    return this.http.put<Entity>(`${this.BASE_URL}/${id}`, t, {
      headers: headers,
    });
  }

  public delete(id: number): Observable<Entity> {
    return this.http.delete<Entity>(`${this.BASE_URL}/${id}`);
  }
}
