import { Injectable, NgZone, inject } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { SignupInterface, User } from './signup/store/signup.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { APP_ACTIONS, selectAuthenticated } from './store/app.store';
import { helper, isTokenExpired } from './utils/Utils';
import { Transaction } from './profile/store/profile.store';
import { ConstantState } from './store/constant.store';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {

  private readonly http = inject(HttpClient);
  private readonly ngZone = inject(NgZone);
  private readonly store = inject(Store);
  private jsonUrl = 'assets/coinHistory.json';
  private readonly apiUrl = environment.apiUrl;
  
  constructor() {}

  loadConstants(): Observable<ConstantState> {
      
    return this.http.get<ConstantState>(`${this.apiUrl}app/constants`,

    )
  }
}
