import { version } from "process";

export const environment = {
  version: '1.0',
  production: false,
  apiUrl: 'https://dev.api.engineering.yashilabs.com/',
  authUrl: 'https://accounts.yashilabs.com/',
  realm: 'engineering',
  client: 'engineering_quiz',
  appUrl: 'https://dev.engineering.yashilabs.com',
  vapidPublicKey:
    'BO8m0QM6UpOgl6HKsg8O4ZN8lDPYbQO-pgKx9nyIeJGmpET89L7idqDpgEPyzZbjPAAWRiNLEZGgEyarF9SfHU8',
  s3Bucket: 'yashi-labs',
  s3Object: 'engineering_quiz/files/',
  s3Url: 'https://yashi-labs.s3.ap-south-1.amazonaws.com/',
  iosBannerAdId: 'ca-app-pub-2145180845810961/4682231447',
  androidBannerAdId: 'ca-app-pub-2145180845810961/8565636139',
  iosInterstitialAdId: 'ca-app-pub-2145180845810961/5370866277',
  androidInterstitalAdId: 'ca-app-pub-2145180845810961/9879551956',
  googleWebClientId:
    '414052595744-lkp1flkho1tm839mniumrbi2trsr0e7k.apps.googleusercontent.com',
  googleIosClientId:
    '414052595744-cvrrsm67ramngermn8thvs5qsnvvpe8m.apps.googleusercontent.com',
  liveUpdateUrl:
    'https://yashi-labs.s3.ap-south-1.amazonaws.com/app-updates/yashi-academy/uat/releases',

  liveUpdateManifestUrl:
    'https://yashi-labs.s3.ap-south-1.amazonaws.com/app-updates/yashi-academy/dev/update-manifest.json',
};
